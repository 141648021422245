<script>
export let client;
export let debug;
import dateformat from "dateformat";
export let date_format ="dd/mm/yyyy h:MM TT";
import Photo from "../shared/photo.svelte";
import Page from "../shared/page.svelte";
import InfoGrid from "../shared/InfoGrid.svelte";
import CardItem from "../tutor_card.component/card-item.svelte"
import IconText from "../tutor_card.component/icon-text.svelte";
import CardRow from "../shared/cards/card-row.svelte";

$:fullname = `${client.name} ${client.surname}`;
$: client_created = client.created_at?dateformat(client.created_at, date_format):"N/a";
</script>


{#if debug}

    <pre>
        {JSON.stringify(client, null, 2)}
    </pre>

{:else}

<Page>
    <div class="flex flex-col m-auto select-none border bg-white w-full">
        <div class="flex flex-col">
            <div class="flex flex-row bg-slate-900 text-white p-2 justify-between">
                <a class="flex flex-row block items-center" href={'/clients/edit/' + client.id} target="_blank">
                    <Photo url={client.photo_url} icon="person" />
                    <div class="flex flex-col items-end md:flex-row md:justify-between  md:items-baseline flex-grow justify-end">
                        <div class="font-bold text-md text-base md:text-3xl md:pl-5 text-right sm:text-left">{fullname}</div>
                    </div>
                </a>
                <div>
                    {#if client.created_at}
                        <div title="Signup Date" class="text-xs text-slate-300 p-2 mx-1 bg-slate-500 cursor-default">
                            {client_created}
                        </div>
                    {/if}
                </div>
            </div>


            <InfoGrid>
                <CardItem title="Client Information">

                    <CardRow>
                        <CardRow>
                            <IconText icon="schedule" css="text-slate-700">
                                {client_created}
                            </IconText>
                        </CardRow>
                        <CardRow>
                            <IconText icon="map" css="text-red-600" text={fullname}>
                                {#if client.homeAddress}
                                    <a href={'http://maps.google.com/?q=' + client.homeAddress.best_format} target="_blank">
                                        {client.homeAddress.best_format}
                                    </a>
                                {:else}
                                    No Address Saved
                                {/if}
                            </IconText>
                        </CardRow>
                    </CardRow>
                </CardItem>

                <CardItem title="Client Contact">
                    <CardRow>
                        <IconText icon="email" css="text-orange-300 ">
                            {#if client.email}
                                <a href={'mailto:' + client.email}>{client.email}</a>
                            {:else}
                                No email saved
                            {/if}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="phone" css="text-slate-600">
                            {#if client.phone}
                                <a href={'tel:' + client.phone}>
                                    {client.phone}&nbsp;(Mobile)
                                </a>
                            {:else}
                                No mobile phone saved
                            {/if}
                        </IconText>
                    </CardRow>
                    <CardRow>
                        <IconText icon="phone" css="text-red-800">
                            {#if client.phone_alternative}
                                <a href={'tel:' + client.phone_alternative}>
                                    {client.phone_alternative}&nbsp;(Home)
                                </a>
                            {:else}
                                No Home phone saved
                            {/if}
                        </IconText>
                    </CardRow>
                </CardItem>
            </InfoGrid>
    </div>
</Page>

{/if}
